<template>
  <div>
    <SectionHeader title="Walmart Grocery - Mobile App"/>
    <div class="m-x-15p mb:m-25">
      <div class="flex flex-col gap-20 p-x-50 mb:p-x-0 mb-40">
        <p><span class="font-robBold">Challenge:</span> Discover pain points of current website and propose solutions to improve user experience in a mobile app.</p>
        <p><span class="font-robBold">Deliverables:</span> Strategy, User research report, competitative anaylsis, affinity map, personas, user journey map, Lo-Fi sketches, UI, and Hi-Fi prototype.</p>
        <p><span class="font-robBold">Role:</span> Product Designer</p>
      </div>
      <img src="@/assets/images/walmart/wal-main.png" alt="" class="w-full mb-40">
    </div>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Goals and Objectives</h1>
        <p class="mt-20">Develop a complimentary mobile experience for Walmart Grocery to help customers easily access grocery products and schedule pick up.</p>
        <h1 class="font-barBold font-24 mt-40 mb-20">Challenge</h1>
        <ul class="mt-0 mb-40">
          <li>The challenge is to understand current Walmart users&apos; response to the website, discover pain points, and propose a mobile solution to improve user experience.</li>
        </ul>
        <h1 class="font-barBold font-24">Process</h1>
        <p class="mt-20">Starting from the initial user research phase, I conducted user interviews and a moderated usability study of the current website to understand the problems users face. I also conducted a competitive analysis to ensure the new app will be up-to-date with current trends. During the synthesis phase, I gathered all of the data and created an affinity map to determine priority pain points. Then I created two personas to ensure empathy during the entire design process. I also created a user journey map to understand where users are having trouble, created Lo-Fi sketches and a Hi-Fi prototype in Figma. By the end, I was able to validate that the user pain points had been solved by the designs. The project timeline was 4 weeks.</p>
      </div>
      <div class="flex-center">
        <img src="@/assets/images/walmart/wal-process.png" alt="" class="m-w-1000 w-full">
      </div>
    </div>
    <hr class="w-650 m-y-0 border-top-gray mb:w-80p">
    <SectionHeader title="RESEARCH"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Preliminary Research</h1>
        <p class="mt-20">Since Walmart does not have a dedicated app for their grocery services, I conducted a competitive analysis of direct and in-direct competitors to ensure the new app will be up-to-date on current trends and provide unique opportunities.</p>
      </div>

      <div class="flex-start-center flex-wrap mb-30">
        <div class="mb:text-center">
          <img class="w-full m-w-750 mb-10" src="@/assets/images/walmart/walcomp1.png" alt="Safeway logo">

          <p class="font-robBold mb-10">DIRECT COMPETITOR</p>
          <p class="font-robBold">STRENGTHS</p>
          <p class="mt-0 mb-5">Attention grabbing icons</p>
          <p class="mt-0 mb-5">Upfront coupons</p>
          <p class="mt-0 mb-5">Meal planning tool</p>
          <p class="mt-0 mb-5">Category navigation is clean</p>

          <p class="font-robBold mt-30">WEAKNESSES</p>
          <p class="mt-0 mb-5">Missing images</p>
          <p class="mt-0 mb-5">Deceptive links</p>
          <p class="mt-0 mb-5">Hard to get started</p>
          <p class="mt-0 mb-5">Hard to discover new items</p>
          <p class="mt-0 mb-5">Difficulty checking out</p>
          <p class="mt-0 mb-5">Substitutions limited</p>
        </div>

        <div class="mb:text-center">
          <img class="w-full m-w-750 mb-10" src="@/assets/images/walmart/walcomp2.png" alt="Food Lion logo">

          <p class="font-robBold mb-10">DIRECT COMPETITOR</p>
          <p class="font-robBold">STRENGTHS</p>
          <p class="mt-0 mb-5">Reserve time slot</p>
          <p class="mt-0 mb-5">Lots of categories</p>
          <p class="mt-0 mb-5">Upfront pricing</p>

          <p class="font-robBold mt-30">WEAKNESSES</p>
          <p class="mt-0 mb-5">Color contrast is hard to read</p>
          <p class="mt-0 mb-5">Separate apps for coupons and shopping</p>
          <p class="mt-0 mb-5">Long loading times</p>
          <p class="mt-0 mb-5">Confusing items list</p>
          <p class="mt-0 mb-5">No way to search generically</p>
          <p class="mt-0 mb-5">Sign in twice to check out</p>
        </div>

        <div class="mb:text-center">
          <img class="w-full m-w-750 mb-10" src="@/assets/images/walmart/walcomp3.png" alt="Uber Eats logo">

          <p class="font-robBold mb-10">INDIRECT COMPETITOR</p>
          <p class="font-robBold">STRENGTHS</p>
          <p class="mt-0 mb-5">Lots filtering options</p>
          <p class="mt-0 mb-5">Dietary options</p>
          <p class="mt-0 mb-5">Easy to navigate</p>
          <p class="mt-0 mb-5">List of food options</p>
          <p class="mt-0 mb-5">Edit meals</p>
          <p class="mt-0 mb-5">Easy to check out</p>

          <p class="font-robBold mt-30">WEAKNESSES</p>
          <p class="mt-0 mb-5">Check out button blinds into background</p>
          <p class="mt-0 mb-5">Not intuitive</p>
        </div>

      </div>

      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">User Research</h1>
        <p class="mt-20"><a href="https://www.businessinsider.com/walmart-shopper-demographics-average-is-white-woman-2020-1#the-average-costco-shopper-was-not-only-older-than-at-any-other-store-but-they-also-had-a-higher-annual-household-income-of-just-over-100000-which-makes-sense-given-that-consumers-are-required-to-pay-membership-fees-to-shop-there-4 " target="_blank" rel="noopener noreferrer">Using this article</a> about the key demographic for Walmart. I selected four frequent Walmart shoppers between the ages of 25-34 and 45-54, two males and two females. Each was interviewed about their thoughts on the current Walmart website, then participated in a usability study using the current site.</p>
      </div>
      
      <div class="flex-center">
        <img src="@/assets/images/walmart/wal-findings.png" alt="" class="w-full m-w-1000 mb-20">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Key Findings</h1>
        <p class="mt-20">I created an affinity map to sort out the data gathered from the usability study and user interviews to better determine the priority pain points.</p>
      </div>
      <div class="flex-center-col">
        <img src="@/assets/images/walmart/wal-re1.png" alt="" class="w-full m-w-750 mb-40">
        <img src="@/assets/images/walmart/wal-re2.png" alt="" class="w-full m-w-750 mb-40">
        <img src="@/assets/images/walmart/wal-re3.png" alt="" class="w-full m-w-750 mb-40">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Personas</h1>
        <p class="m-y-20">After the data was processed, I created two user personas based on research to communicate the struggles the key demographic face for the duration of the design.</p>
      </div>
      <div class="flex-center-col">
        <img src="@/assets/images/walmart/wal-armin.png" alt="" class="w-full m-w-750 mb-20">
        <img src="@/assets/images/walmart/wal-felicia.png" alt="" class="w-full m-w-750 mb-20">
      </div>
    </div>

    <hr class="w-650 m-y-40 border-top-gray mb:w-80p">
    <SectionHeader title="IDEATION"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">User Journey Map</h1>
        <p class="m-y-20">I created a user journey map to ensure an intuitive user experience. This allowed me to pinpoint any additional problems or opportunities before starting the design phase.</p>
      </div>
      <div class="flex flex-wrap mb:justify-center mb-20">
        <!--Col 1-->
        <div class="m-w-250">
          <div class="bg-wal-1 flex-center-col ptb-10">
            <p class="font-robBold m-0">ACTION</p>
            <p class="font-robBold m-0">1. Open Walmart app and sign in</p>
          </div>
          <div class="bg-wal-1 p-10">
            <p class="font-robBold bg-wal-1 m-0 text-center mb-10">TASK LIST</p>
            <div class="bg-white p-10 h-150">
              <ol class="m-0 prl-20" type="A">
                <li class="mb-5">Open the Walmart Grocery app</li>
                <li class="mb-5">Create a new account</li>
                <li class="mb-5">Login</li>
              </ol>
            </div>
            <p class="font-robBold m-0 text-center mtb-10">FEELINGS</p>
            <div class="bg-white p-10 h-150">
              <ul class="m-0 prl-20">
                <li class="mb-5">Excited</li>
                <li class="mb-5">Anxious</li>
              </ul>
            </div>
            <p class="font-robBold m-0 text-center mtb-10">IMPROVEMENT OPPORTUNITIES</p>
            <div class="bg-white p-10 h-175">
              <ul class="m-0 prl-20">
                <li class="mb-5">Intuitive account form</li>
                <li class="mb-5">Option to view as a guest</li>
              </ul>
            </div>
          </div>
        </div>

        <!--Col 2-->
        <div class="m-w-250">
          <div class="bg-wal-2 flex-center-col ptb-10">
            <p class="font-robBold m-0">ACTION</p>
            <p class="font-robBold m-0">2. Browse products</p>
          </div>
          <div class="bg-wal-2 p-10">
            <p class="font-robBold bg-wal-2 m-0 text-center mb-10">TASK LIST</p>
            <div class="bg-white p-10 h-150">
              <ol class="m-0 prl-20" type="A">
                <li class="mb-5">Select category</li>
                <li class="mb-5">Scroll through items</li>
                <li class="mb-5">Click on a product</li>
              </ol>
            </div>
            <p class="font-robBold m-0 text-center mtb-10">FEELINGS</p>
            <div class="bg-white p-10 h-150">
              <ul class="m-0 prl-20">
                <li class="mb-5">Interested in options</li>
                <li class="mb-5">Overwhelmed</li>
              </ul>
            </div>
            <p class="font-robBold m-0 text-center mtb-10">IMPROVEMENT OPPORTUNITIES</p>
            <div class="bg-white p-10 h-175">
              <ul class="m-0 prl-20">
                <li class="mb-5">Search box to find exactly what you need</li>
                <li class="mb-5">Prompts to explore popular items/new items</li>
              </ul>
            </div>
          </div>
        </div>

        <!--Col 3-->
        <div class="m-w-250">
          <div class="bg-wal-3 flex-center-col ptb-10">
            <p class="font-robBold m-0">ACTION</p>
            <p class="font-robBold m-0">3. Add to cart</p>
          </div>
          <div class="bg-wal-3 p-10">
            <p class="font-robBold bg-wal-3 m-0 text-center mb-10">TASK LIST</p>
            <div class="bg-white p-10 h-150">
              <ol class="m-0 prl-20" type="A">
                <li class="mb-5">Decide on product</li>
                <li class="mb-5">Read over details/price</li>
                <li class="mb-5">Add to cart</li>
              </ol>
            </div>
            <p class="font-robBold m-0 text-center mtb-10">FEELINGS</p>
            <div class="bg-white p-10 h-150">
              <ul class="m-0 prl-20">
                <li class="mb-5">Determined</li>
                <li class="mb-5">Relieved</li>
                <li class="mb-5">Excited</li>
              </ul>
            </div>
            <p class="font-robBold m-0 text-center mtb-10">IMPROVEMENT OPPORTUNITIES</p>
            <div class="bg-white p-10 h-175">
              <ul class="m-0 prl-20">
                <li class="mb-5">Quick and easy to read info</li>
                <li class="mb-5">Attention grabbing add to cart button</li>
                <li class="mb-5">Product suggestions/similar</li>
              </ul>
            </div>
          </div>
        </div>

        <!--Col 4-->
        <div class="m-w-250">
          <div class="bg-wal-4 color-white flex-center-col ptb-10">
            <p class="font-robBold m-0">ACTION</p>
            <p class="font-robBold m-0">4. Check out</p>
          </div>
          <div class="bg-wal-4 p-10">
            <p class="font-robBold color-white bg-wal-4 m-0 text-center mb-10">TASK LIST</p>
            <div class="bg-white p-10 h-150">
              <ol class="m-0 prl-20" type="A">
                <li class="mb-5">Click on cart icon</li>
                <li class="mb-5">Review cart</li>
                <li class="mb-5">Click check out</li>
              </ol>
            </div>
            <p class="font-robBold m-0 color-white text-center mtb-10">FEELINGS</p>
            <div class="bg-white p-10 h-150">
              <ul class="m-0 prl-20">
                <li class="mb-5">Confused where to find cart</li>
                <li class="mb-5">Annoyed by scrolling</li>
              </ul>
            </div>
            <p class="font-robBold m-0 color-white text-center mtb-10">IMPROVEMENT OPPORTUNITIES</p>
            <div class="bg-white p-10 h-175">
              <ul class="m-0 prl-20">
                <li class="mb-5">Check out button floats</li>
                <li class="mb-5">Total price front and center in cart </li>
                <li class="mb-5">Pop-up 'check-out?' prompt</li>
              </ul>
            </div>
          </div>
        </div>

        <!--Col 5-->
        <div class="m-w-250">
          <div class="bg-wal-5 color-white flex-center-col ptb-10">
            <p class="font-robBold m-0">ACTION</p>
            <p class="font-robBold m-0">5. Schedule pick-up</p>
          </div>
          <div class="bg-wal-5 p-10">
            <p class="font-robBold bg-wal-5 color-white m-0 text-center mb-10">TASK LIST</p>
            <div class="bg-white p-10 h-150">
              <ol class="m-0 prl-20" type="A">
                <li class="mb-5">Add in check out details</li>
                <li class="mb-5">Select pick up location</li>
                <li class="mb-5">Select date and time</li>
                <li class="mb-5">Confirm check out</li>
              </ol>
            </div>
            <p class="font-robBold m-0 color-white text-center mtb-10">FEELINGS</p>
            <div class="bg-white p-10 h-150">
              <ul class="m-0 prl-20">
                <li class="mb-5">Annoyed about entering details</li>
                <li class="mb-5">Confused by selecting store</li>
                <li class="mb-5">Anxious about forgetting time</li>
              </ul>
            </div>
            <p class="font-robBold m-0 color-white text-center mtb-10">IMPROVEMENT OPPORTUNITIES</p>
            <div class="bg-white p-10 h-175">
              <ul class="m-0 prl-20">
                <li class="mb-5">Remember checkout details</li>
                <li class="mb-5">Add favorite stores</li>
                <li class="mb-5">Maps of available locations</li>
                <li class="mb-5">Email/text reminders</li>
                <li class="mb-5">Email confirming time/date</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Lo-Fi Sketches</h1>
        <p class="m-y-20">Based on the established user pain points, I sketched multiple options for each screen. During the process of the design, I continuously referred to the target audience and research data to ensure the designs were focused on improving the user experience.</p>
      </div>
      
      <div class="flex-center-between gap-20 flex-wrap">
        <img src="@/assets/images/walmart/wal1.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal2.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal3.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal4.png" alt="" class="m-h-285 mb:w-full mb:m-h-reset">
      </div>

      <div class="p-x-30 mb:p-x-0">
        <p class="mt-20 mb-10">Based on my research insights, I prioritized three main objectives:</p>
        <ul class="m-0">
          <li>Provide a platform where users can easily search for the items they need using a variety of filter, sort, and search options.</li>
          <li>Provide a platform where users can discover new items, categories, and see related products.</li>
          <li>Provide a platform where users can search for and choose the substitutions they want.</li>
        </ul>
      </div>
    </div>

    <hr class="w-650 m-y-40 border-top-gray mb:w-80p">
    <SectionHeader title="PROTOTYPE"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Hi-Fi Prototype</h1>
        <p class="m-y-20">Moving forward, I turned my Lo-Fi sketches into a Hi-Fi prototype.</p>
      </div>

      <div class="flex-center-between gap-20 flex-wrap">
        <img src="@/assets/images/walmart/wal-hifi1.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal-hifi2.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal-hifi3.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal-hifi4.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
      </div>

      <div class="flex-center-between gap-20 flex-wrap mb-20">
        <img src="@/assets/images/walmart/wal-hifi5.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal-hifi6.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal-hifi7.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
        <img src="@/assets/images/walmart/wal-hifi8.png" alt="" class="m-h-385 mb:w-full mb:m-h-reset">
      </div>

      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Clickable Prototype</h1>
        <p class="m-y-20 mb-40">The <a href="https://www.figma.com/proto/4mbBFoBwkAg87mWlA9IJ7X/Walmart-Hi-Fi-Prototype-2-21-22?node-id=309%3A172&scaling=scale-down&page-id=0%3A1&starting-point-node-id=301%3A105" target="_blank" rel="noopener noreferrer">clickable prototype</a> was created to test and validate the design. It was very useful in understanding how real users navigate the app and I gained realistic insights on what worked well and what required further improvement. The prototype only includes the main user flow and aspects that I planned to test users on.</p>
      </div>

      <iframe class="w-full h-400" style="border: 1px solid rgba(0, 0, 0, 0.1);" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F4mbBFoBwkAg87mWlA9IJ7X%2FWalmart-Hi-Fi-Prototype-2-21-22%3Fnode-id%3D309%253A172%26scaling%3Dscale-down%26page-id%3D0%253A1%26starting-point-node-id%3D301%253A105" allowfullscreen></iframe>
    </div>
    <hr class="w-650 m-y-40 border-top-gray mb:w-80p">
    
    <SectionHeader title="VALIDATE"/>
    <div class="m-x-15p mb:m-25">
      <div class="flex-center">
        <img src="@/assets/images/walmart/wal-val.png" class="m-h-285 mb:w-full mb:m-h-reset" alt="">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Conclusion</h1>
        <p class="m-y-20">After four weeks of user research, analyzing, and designing, I was able to create a Hi-Fi prototype and validate the assumptions and changes I made. Using the clickable prototype with four participates, I received the following data:</p>
        <p class="mt-20 mb-10">Below is the results from our Hi-Fi usability study:</p>
        <ul class="mt-0 mb-40">
          <li>(60% increase) 5 out of 5 users were able to successfully check out and reserve pick-up.</li>
          <li>(20% increase) 4 out of 5 users were able to successfully add an item to the cart.</li>
          <li>(60% increase) 4 out of 5 users were able to virtually select substitutions</li>
          <li>(40% increase) 5 out of 5 users intuitively knew how to sort and filter the search.</li>
        </ul>
      </div>
    </div>
    <hr class="w-650 m-y-40 border-top-gray mb:w-80p">
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">After Thoughts</h1>
        <p class="m-y-20">This UX case study was an amazing learning experience. At the beginning of this project, I was fascinated to learn how other users interacted with the Walmart website when shopping for groceries. It was very insightful to see how differently each user used it. This gave me valuable insight into the issues users face.</p>
        <p class="mb-20">This project really showed me how important research is and how each piece of data is a valuable part to the puzzle.</p>
        <p class="mb-50 font-robItalic">Note: I do not work for, nor am I affiliated with Walmart. This UX study was done as a learning experience to explore the products I use and how to improve them.</p>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from '../components/SectionHeader.vue'
export default {
  components: { SectionHeader },
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
