<template>
  <h1 class="font-36 mt-20 mb-40 font-barBold text-center m-x-25">{{ title }}</h1>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style scoped>

</style>
